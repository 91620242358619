import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
const HeroSection = () => {

    const { t } = useTranslation();
    const translations = t("pages",{ returnObjects: true })

    return (
        <section className="slider-area hero-height position-relative fix" style={{ backgroundImage: "url(/assets/img/slider/main_bg.webp)" }}>
            <img className="shape-3 d-none d-xxl-block" src="/assets/img/shape/shape-03.png" alt="img not found" />
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-9">
                        <div className="hero-text pt-95">
                            <h5>{translations.home.hero.discoverPotential}</h5>
                            <h2>{translations.home.hero.learnWebDevelopment}</h2>
                            <p>{translations.home.hero.independentEducation}</p>
                            <div className="hero-btn">
                                <Link to="courses" className="edu-btn">{translations.home.hero.ctaButton}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="hero-right position-relative">
                            <img data-depth="0.2" className="shape shape-1" src="/assets/img/shape/shape-01.png" alt="shape" />
                            <img data-depth="0.2" className="shape-2" src="/assets/img/shape/shape-02.png" alt="shape" />
                            <img className="shape-6" src="/assets/img/shape/slider-shape-6.png" alt="shape" />
                            <div className="shape-4">
                                <img className="" src="/assets/img/shape/shape-04.png" alt="shape" />
                                <h5 className="hero-shape-text">{translations.home.hero["HighlyQualifiedInstructors"]}</h5>
                            </div>
                            <div className="shape-5">
                                <h5>{translations.home.hero["MoreOf"]} <span>100+</span> {translations.home.hero["StudentsRecommendUs"]}.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;