import React from 'react';
import CourseTab from '../elements/tabs/courseTab';
import Footer from '../footer/footer';
import Header from '../header/header';
import FeatureSection from './featureSection';
import HeroSection from './heroSection';
import AboutSection from '../homeTwo/aboutSection';

const index = () => {
    return (
        <>
        <Header />
            <main>
                <HeroSection />
                <CourseTab />
                <AboutSection/>
                <FeatureSection />
            </main>
            <Footer />
        </>
    );
};

export default index;

