import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import HomeMain from "../components/home"
import { useTranslation } from 'react-i18next';
import {graphql} from 'gatsby';
const IndexPage = () => {
  
  const { t } = useTranslation();

  return(
    <Layout>
      <Seo
        title={t('siteTitle')}
        description={t('siteDescription')}
      />
      <HomeMain />
    </Layout>
  )

}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

