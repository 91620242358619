import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutSection = () => {

    const { t } = useTranslation();
    const translations = t("pages",{ returnObjects: true })
    
    return (
        <section className="about-area p-relative pt-90 pb-70">
            <div className="container">
                <img className="about-shape" src="/assets/img/shape/education-shape-03.png" alt="shape" />
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="about-img position-relative mb-50">
                            <div className="about-main-img">
                                <StaticImage src="../../assets/img/about/about-img-1.png" alt="about" />
                            </div>
                            <StaticImage className="about-shape-1" src="../../assets/img/shape/education-shape-01.png" alt="about" />
                            <StaticImage className="about-shape-2" src="../../assets/img/shape/education-shape-02.png" alt="about" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5">
                        <div className="about-content mb-50">
                            <div className="section-title mb-30">
                                <h2>{translations.home.aboutSection["sectionTitle"]}<span className="down-mark-line-2">Apprende.Online</span></h2>
                            </div>
                            <div className="student-choose-list">
                                <p className="mb-30">{translations.home.aboutSection["whyChooseApprende"]}</p>
                                <ul>
                                    {translations.home.aboutSection.benefitsList.map((data)=>(
                                        <li key={data}><i className="fas fa-check-circle"></i>{data}</li>
                                    ))}
                                </ul>
                            </div>
                            <Link to="courses" className="edu-btn">{t("ApplyNow")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;