import React from 'react';
import { useTranslation } from 'react-i18next';

const FeatureSection = () => {
    
    const { t } = useTranslation();
    const translations = t("pages",{ returnObjects: true })

    return (
        <div className="features-area pt-45 pb-15" style={{ backgroundImage: "url(/assets/img/fact/fact.webp)" }}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="features-wrapper d-flex align-items-center mb-30">
                            <div className="features-icon">
                                <i className="flaticon-online-course"></i>
                            </div>
                            <div className="features-content">
                                <h3>{translations.home.featureSection["TrainYourSkillsWithRealProjects"]}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="features-wrapper d-flex align-items-center mb-30">
                            <div className="features-icon">
                                <i className="flaticon-certificate"></i>
                            </div>
                            <div className="features-content">
                                <h3>{translations.home.featureSection["CertificateOfCompletion"]}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="features-wrapper d-flex align-items-center mb-30">
                            <div className="features-icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <div className="features-content">
                                <h3>{t('Slogan')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureSection;