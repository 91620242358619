import React, { useState } from 'react';

const HeaderTop = () => {
    const [isActiveF, setActiveF] = useState("false");

    const handleToggleF = () => {
        setActiveF(!isActiveF);
    };
    return (
        <div className={`header-note-area p-relative d-none d-md-block ${isActiveF ? "eduman-header-notice-visible" : "eduman-header-notice-hide"}`}>
            <div className="container-fluid">
                <div className="note-text text-center">
                    <p>Compra <span>con 35% off</span> usando el siguiente cupón APRND352024 corre por que solo hay 50 cupones</p>
                </div>
            </div>
            <div className="eduman-header-notice-action-close">
                <button onClick={handleToggleF}><i className="fal fa-times"></i></button>
            </div>
        </div>
    );
};

export default HeaderTop;