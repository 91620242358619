import { Link } from 'gatsby';
import React from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../config';
import { formatCurrency } from '../../../utils';
import StarRating from '../../starRating';
import { useTranslation } from 'react-i18next';
const CourseTab = () => {

    const { t } = useTranslation();
    const translations = t("pages",{ returnObjects: true })
    
    const [courses, setCourses] = React.useState(null)

    React.useEffect(()=>{
        getCourses();
    },[])

    const getCourses = async () => {
        try {
            const result = await axios.get(BASE_URL+"/courses");
            setCourses(result.data);
        } catch (error) {
            
        }
    }

    
    
    return (
        <section className="course-area p-relative pt-110 pb-90">
            <div className="course-shape-1">
                <img src="/assets/img/shape/portfolio-shap-1.png" alt="shape" />
            </div>
            <div className="course-shape-2">
                <img src="/assets/img/shape/portfolio-shap-2.png" alt="shape" />
            </div>
            <div className="course-shape-3">
                <img src="/assets/img/shape/portfolio-shap-3.png" alt="shape" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5 f-left">
                        <div className="section-title mb-50">
                            <h2>{translations.home.courseTab["DiscoverTheCourses"]}<br />
                                 <span className="down-mark-line">{translations.home.courseTab.WebDevelopment}</span></h2>
                        </div>
                    </div>
                    {/* <div className="col-xl-7 col-lg-7">
                        <div className="portfolio-button mt-60">
                            <nav>
                                <div className="nav portfolio-button-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Ver Todos<span className="port-red">[06]</span></button>
                                    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Front<span className="port-red">[01]</span></button>
                                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Back<span className="port-red">[03]</span></button>
                                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Html<span className="port-red">[03]</span></button>
                                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Css<span className="port-red">[03]</span></button>
                                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">React<span className="port-red">[03]</span></button>
                                </div>
                            </nav>
                        </div>
                    </div> */}
                </div>
                <div className="course-main-items">
                    <div className="tab-content portfolio-tabs-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className='row'>
                                {courses && courses.slice(0, 3).map((data, index)=>{
                                    return(
                                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                                    <div className="apprende-online-main-wrapper mb-30">
                                        <div className="course-cart">
                                            <div className="course-info-wrapper">
                                                <div className="cart-info-body">
                                                    <span className="category-color category-color-1"><Link to="/courses">{data.subcategory?.name}</Link></span>
                                                    <Link to={"course-details/"+data.slug}><h3>{data.name}</h3></Link>
                                                    <div className="cart-lavel">
                                                        <h5>{t('Level')} : <span>{data.level}</span></h5>
                                                        {/* <div dangerouslySetInnerHTML={{ __html: data.description }} ></div> */}
                                                        
                                                    </div>
                                                    <div className="info-cart-text">
                                                        <ul>
                                                            {data.willLearn.map((data,index)=>(
                                                                <li key={index}><i className="far fa-check"></i>{data}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="course-action">
                                                        <Link to={"course-details/"+data.slug} className="view-details-btn">{t('ViewDetails')}</Link>
                                                        {/* <button className="wishlist-btn"><i className="flaticon-like"></i></button>
                                                        <Link to={"/course-details/"+data.slug} className="c-share-btn"><i className="flaticon-previous"></i></Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="apprende-online-thumb apprende-online-image-container w-img">
                                            <Link to={"course-details/"+data.slug}>
                                                <img src={data.introImage} alt="course-img" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                                                </Link>
                                        </div>
                                        <div className="apprende-online-wraper">
                                            <div className="apprende-online-heading">
                                                <Link to="/courses" className="course-link-color-1">{data.subcategory?.name}</Link>
                                                <StarRating rating={data.rating} count={data.countReviews} />
                                            </div>
                                            <div className="apprende-online-text">
                                                <h3><Link to={"course-details/"+data.slug}>{data.name}</Link></h3>
                                            </div>
                                            <div className="apprende-online-meta">
                                                <div className="apprende-online-price">
                                                    {data.isFree && <span className="price-now">{t('FREE')}</span>}
                                                    {!data.isFree &&
                                                    <>
                                                        <span className="price-now">USD ${formatCurrency(data.priceNow)} </span>
                                                        {data.priceOld > 0 && <del className="price-old">${formatCurrency(data.priceOld)}</del>}
                                                    </>
                                                    }
                                                    
                                                </div>
                                                <div className="apprende-online-tutor"><img src={data?.teacher?.photo} style={{width: '32px', borderRadius: '50%'}}
                                                    alt="img not found" />
                                                    <Link to={"/instructor-profile/"+data?.teacher?.id}><span>{data?.teacher?.firstName}</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="apprende-online-footer">
                                            <div className="course-lessson-svg">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.471" height="16.471"
                                                    viewBox="0 0 16.471 16.471">
                                                    <g id="blackboar09" transform="translate(-0.008)">
                                                        <path id="Path_01" data-name="Path 101"
                                                            d="M16,1.222H8.726V.483a.483.483,0,1,0-.965,0v.74H.491A.483.483,0,0,0,.008,1.7V13.517A.483.483,0,0,0,.491,14H5.24L4.23,15.748a.483.483,0,1,0,.836.483L6.354,14H7.761v1.99a.483.483,0,0,0,.965,0V14h1.407l1.288,2.231a.483.483,0,1,0,.836-.483L11.247,14H16a.483.483,0,0,0,.483-.483V1.7A.483.483,0,0,0,16,1.222Zm-.483.965v8.905H.973V2.187Zm0,10.847H.973v-.976H15.514Z"
                                                            fill="#575757" />
                                                    </g>
                                                </svg>
                                                <span className="ms-2">{data.countLessons} {t('Secciones')}</span>
                                            </div>
                                            <div className="course-lessson-svg">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.471" height="16.471"
                                                    viewBox="0 0 16.471 16.471">
                                                    <g id="blackboar09" transform="translate(-0.008)">
                                                        <path id="Path_01" data-name="Path 101"
                                                            d="M16,1.222H8.726V.483a.483.483,0,1,0-.965,0v.74H.491A.483.483,0,0,0,.008,1.7V13.517A.483.483,0,0,0,.491,14H5.24L4.23,15.748a.483.483,0,1,0,.836.483L6.354,14H7.761v1.99a.483.483,0,0,0,.965,0V14h1.407l1.288,2.231a.483.483,0,1,0,.836-.483L11.247,14H16a.483.483,0,0,0,.483-.483V1.7A.483.483,0,0,0,16,1.222Zm-.483.965v8.905H.973V2.187Zm0,10.847H.973v-.976H15.514Z"
                                                            fill="#575757" />
                                                    </g>
                                                </svg>
                                                <span className="ms-2">{data.countVideos} Videos</span>
                                            </div>
                                            <div className="course-deteals-btn">
                                                <Link to={"course-details/"+data.slug}><span className="me-2">{t('ViewDetails')}</span><i className="far fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    )
                                })}
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseTab;